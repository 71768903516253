import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "agile-teams__list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_Column, { span: [ 2, 3 ] }, {
        default: _withCtx(() => [
          _createVNode(_component_Heading, {
            tag: "h2",
            innerHTML: _ctx.agileTeams.heading
          }, null, 8, ["innerHTML"]),
          _createVNode(_component_Button, {
            class: "mt-12",
            route: _ctx.Route.Blog
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.agileTeams.label), 1)
            ]),
            _: 1
          }, 8, ["route"])
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        class: "mt-16 md:mt-4",
        span: [ 2, 2 ],
        push: [ 0, 1 ]
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.agileTeams.team, (team, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "text-base font-body text-gray-600"
              }, _toDisplayString(team), 1))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}