import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, null, {
    default: _withCtx(() => [
      (_ctx.title)
        ? (_openBlock(), _createBlock(_component_Column, {
            key: 0,
            span: [ 2, _ctx.stacked ? 4 : 2 ],
            push: [ 0, _ctx.stacked ? 1 : 0 ]
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Paragraph, {
                class: "title-list__title",
                innerHTML: _ctx.title
              }, null, 8, ["innerHTML"])
            ]),
            _: 1
          }, 8, ["span", "push"]))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, idx) => {
        return (_openBlock(), _createBlock(_component_Column, {
          key: `${item}.${idx}`,
          span: [ 2, 2 ],
          push: _ctx.stacked ? [ 0, idx === 0 ? 1 : 0 ] : [ 0, _ctx.title ? 0 : idx === 0 ? 1 : 0 ],
          class: _normalizeClass([ idx === 1 ? 'sm:mt-0' : _ctx.title ? 'sm:mt-6' : 'sm:mt-0', { 'mt-12': _ctx.stacked } ])
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (tech, id) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: id }, [
                (_ctx.careers)
                  ? (_openBlock(), _createBlock(_component_Anchor, {
                      key: 0,
                      class: "title-list__item --anchor",
                      route: { name: _ctx.Route.CareersSingle, params: { slug: tech?.slug ?? '' } }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(tech.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["route"]))
                  : ((typeof(tech) === 'object' && tech.type === 'link' ))
                    ? (_openBlock(), _createBlock(_component_Anchor, {
                        key: 1,
                        class: "title-list__item --anchor",
                        route: { name: _ctx.Route.Services, params: { slug: tech?.entry?.slug ?? '' } }
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tech.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["route"]))
                    : ((typeof(tech) === 'object' && tech.type === 'text' ))
                      ? (_openBlock(), _createBlock(_component_Paragraph, {
                          key: 2,
                          class: "title-list__item",
                          "no-margin": "",
                          innerHTML: tech.text
                        }, null, 8, ["innerHTML"]))
                      : (_openBlock(), _createBlock(_component_Paragraph, {
                          key: 3,
                          class: "title-list__item",
                          "no-margin": "",
                          innerHTML: tech
                        }, null, 8, ["innerHTML"]))
              ], 64))
            }), 128))
          ]),
          _: 2
        }, 1032, ["push", "class"]))
      }), 128))
    ]),
    _: 1
  }))
}