import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_TitleAndList = _resolveComponent("TitleAndList")!
  const _component_Container = _resolveComponent("Container")!
  const _component_AgileTeams = _resolveComponent("AgileTeams")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_DynamicContent = _resolveComponent("DynamicContent")!
  const _component_OpenSourcePackageSkeleton = _resolveComponent("OpenSourcePackageSkeleton")!
  const _component_OpenSourcePackage = _resolveComponent("OpenSourcePackage")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, null, {
      default: _withCtx(() => [
        (_ctx.service.title)
          ? (_openBlock(), _createBlock(_component_Breadcrumb, {
              key: 0,
              class: "mt-32 md:mt-56",
              paths: [
                { route: _ctx.Route.WhatWeDo, text: 'What we do' },
                { route: { name: _ctx.Route.Services, params: { slug: _ctx.service.slug } }, text: _ctx.service?.title }
            ]
            }, null, 8, ["paths"]))
          : _createCommentVNode("", true),
        _createVNode(_component_PageHeader, {
          class: "mt-7",
          header: _ctx.service.page_header_heading,
          "sub-header": _ctx.service.page_header_content?.text,
          "header-span": 5
        }, null, 8, ["header", "sub-header"]),
        _createVNode(_component_Grid, {
          collapsed: "",
          class: "mt-20 md:mt-28"
        }, {
          default: _withCtx(() => [
            (_ctx.service.cover)
              ? (_openBlock(), _createBlock(_component_Picture, {
                  key: 0,
                  image: _ctx.service.cover,
                  cover: "",
                  width: "100%",
                  maxWidth: "100%",
                  height: [ 220, 440 ]
                }, null, 8, ["image"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_AOS, {
          type: "fade-up",
          easing: "ease",
          duration: "800",
          delay: "1000"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, { class: "mt-24" }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createElementVNode("img", {
                        src: _ctx.service.service_section_icon?.permalink,
                        alt: ""
                      }, null, 8, _hoisted_2),
                      _createVNode(_component_Paragraph, {
                        class: "ml-6 text-black",
                        innerHTML: _ctx.service.service_section_title
                      }, null, 8, ["innerHTML"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_AOS, {
          type: "fade-up",
          easing: "ease",
          duration: "800",
          delay: "1000"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service.service_section_descriptions, (service, index) => {
                  return (_openBlock(), _createBlock(_component_Column, {
                    key: index,
                    span: 2,
                    class: _normalizeClass([ index === 0 ? 'sm:mt-10 mt-20' : 'mt-20' ])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Heading, {
                        tag: "h3",
                        "less-margin": ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(service.title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_Paragraph, { class: "text-gray-600 md:pr-10" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(service.description), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["class"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service.service_section_tech_and_team, (feature, index) => {
          return (_openBlock(), _createBlock(_component_Container, {
            fluid: "",
            key: index
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TitleAndList, {
                title: feature.title,
                list: feature.items,
                index: index,
                class: "mt-28"
              }, null, 8, ["title", "list", "index"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        _createVNode(_component_AOS, {
          type: "fade-up",
          easing: "ease",
          duration: 800,
          delay: 2000
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AgileTeams, { class: "mt-24 md:mt-48" })
          ]),
          _: 1
        }),
        _createVNode(_component_AOS, {
          type: "fade",
          easing: "ease",
          duration: 800,
          delay: 500
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, { class: "mt-24 md:mt-48" }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, { span: 4 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Paragraph, {
                      heading: "",
                      innerHTML: _ctx.service.related_cases_title
                    }, null, 8, ["innerHTML"]),
                    _createVNode(_component_Heading, {
                      tag: "h2",
                      class: "mt-12 md:mt-20",
                      innerHTML: _ctx.service.related_cases_heading
                    }, null, 8, ["innerHTML"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.service.related_cases)
          ? (_openBlock(), _createBlock(_component_Projects, {
              key: 1,
              data: _ctx.service.related_cases,
              class: "pt-16 pb-24 md:pb-48"
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_ctx.service.include_open_source)
      ? (_openBlock(), _createBlock(_component_ThemeChanger, {
          key: 0,
          to: _ctx.Theme.Blue
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, { class: "md:pt-24" }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, { span: 3 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AOS, {
                      type: "fade-up",
                      easing: "ease",
                      duration: 800,
                      delay: 500
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Paragraph, {
                          heading: "",
                          innerHTML: _ctx.service.open_source_section_title
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.service.open_source_section_content)
                  ? (_openBlock(), _createBlock(_component_Column, {
                      key: 0,
                      span: 3,
                      class: "sm:mt-8 items-end"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_AOS, {
                          type: "fade-up",
                          easing: "ease",
                          duration: 800,
                          delay: 500
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_DynamicContent, {
                              content: _ctx.service.open_source_section_content
                            }, null, 8, ["content"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Grid, { class: "mt-16 md:mt-24" }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, { span: [ 2, 4 ] }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AOS, {
                      type: "fade-up",
                      easing: "ease",
                      duration: 800,
                      delay: 500
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          innerHTML: _ctx.service.open_source_section_heading
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_Container, {
              fluid: "",
              class: _normalizeClass(["mt-16 md:mt-24 space-y-24", { 'pb-24 md:pb-48': !_ctx.service.open_source_section_entry }])
            }, {
              default: _withCtx(() => [
                (_ctx.isLoading)
                  ? (_openBlock(), _createBlock(_component_AOS, {
                      key: 0,
                      type: "fade-up",
                      easing: "ease",
                      duration: 800,
                      delay: 500
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (rows) => {
                          return _createVNode(_component_OpenSourcePackageSkeleton, { key: rows })
                        }), 64))
                      ]),
                      _: 1
                    }))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.openSourcePackages, (pkg, index) => {
                      return (_openBlock(), _createBlock(_component_Grid, { key: index }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AOS, {
                            type: "fade-up",
                            easing: "ease",
                            duration: 800,
                            delay: 500 * (index + 1)
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_OpenSourcePackage, {
                                data: pkg.data
                              }, null, 8, ["data"])
                            ]),
                            _: 2
                          }, 1032, ["delay"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
              ]),
              _: 1
            }, 8, ["class"]),
            (_ctx.service.open_source_section_entry)
              ? (_openBlock(), _createBlock(_component_Container, {
                  key: 0,
                  fluid: "",
                  class: "mt-16 md:mt-24 pb-24 md:pb-48"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Grid, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, { span: [ 2, 6 ] }, {
                          default: _withCtx(() => [
                            _createVNode(_component_AOS, {
                              type: "fade-up",
                              easing: "ease",
                              duration: 800,
                              delay: 500
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Button, {
                                  style: {"height":"75px"},
                                  route: _ctx.service.open_source_section_entry.slug
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.service.open_source_section_label), 1)
                                  ]),
                                  _: 1
                                }, 8, ["route"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Dark
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Container, {
          class: "pt-24 md:pt-48",
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AOS, {
              type: "fade-up",
              easing: "ease",
              duration: 800,
              delay: 1000
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      span: [ 2, 3 ],
                      class: "services__reason__heading pb-12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          innerHTML: _ctx.service.why_section_title
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      span: 3,
                      class: "services__reason pb-12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Paragraph, {
                          innerHTML: _ctx.service.why_section_description
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service.why_section_reasons, (reason, index) => {
              return (_openBlock(), _createBlock(_component_Grid, { key: index }, {
                default: _withCtx(() => [
                  _createVNode(_component_AOS, {
                    type: "fade-up",
                    easing: "ease",
                    duration: 800,
                    delay: 700
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        span: 3,
                        class: "services__reason__heading sm:pt-12 sm:pb-6 md:py-12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Heading, {
                            tag: "h2",
                            innerHTML: reason.title
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_AOS, {
                    type: "fade-up",
                    easing: "ease",
                    duration: 800,
                    delay: 700
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, {
                        span: 3,
                        class: "services__reason sm:pb-12 md:py-12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Paragraph, {
                            innerHTML: reason.description
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.service.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 1,
          seo: _ctx.service.seo,
          "structured-data": _ctx.service.seo_structured_data
        }, null, 8, ["seo", "structured-data"]))
      : _createCommentVNode("", true)
  ], 64))
}