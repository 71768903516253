import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-black"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Anchor = _resolveComponent("Anchor")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!

  return (_openBlock(), _createBlock(_component_Grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_Column, null, {
        default: _withCtx(() => [
          _createVNode(_component_Paragraph, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paths, (path, index) => {
                return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                  _createVNode(_component_Anchor, {
                    route: path.route,
                    class: "text-pink-300 uppercase font-normal",
                    bold: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(path.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["route"]),
                  (index !== _ctx.paths.length - 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, " / "))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}