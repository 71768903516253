

    import { defineComponent, ref, watchEffect, nextTick, getCurrentInstance } from 'vue'
    import Grid from '../components/Grid/Grid.vue'
    import Column from '../components/Grid/Column.vue'
    import Breadcrumb from '../components/Breadcrumb.vue'
    import AgileTeams from '../components/AgileTeams.vue'
    // import BlockRevealAnimation from '../components/BlockRevealAnimation.vue'
    import Button from '../components/Button.vue'
    import Container from '../components/Container.vue'
    import DynamicContent from '../components/DynamicContent.vue'
    import PageHeader from '../components/PageHeader.vue'
    import OpenSourcePackage from '../components/OpenSourcePackage.vue'
    import OpenSourcePackageSkeleton from '../components/OpenSourcePackageSkeleton.vue'
    import Heading from '../components/Heading.vue'
    import Paragraph from '../components/Paragraph.vue'
    import Projects from '../components/Projects.vue'
    import Picture from '../components/Picture.vue'
    import TitleAndList from '../components/TitleAndList.vue'
    import MetaInfo from '../components/MetaInfo.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import { useCms } from '../compositions/UseCms'
    import { Route } from '../enums/Route'
    import { Theme } from '../enums/Theme'
    import { useRoute } from 'vue-router'

    export default defineComponent({
        name: 'Services',
        components: {
            AgileTeams,
            Breadcrumb,
            Button,
            // BlockRevealAnimation,
            DynamicContent,
            Grid,
            Column,
            Container,
            PageHeader,
            Projects,
            OpenSourcePackage,
            OpenSourcePackageSkeleton,
            Picture,
            Paragraph,
            Heading,
            MetaInfo,
            TitleAndList,
            ThemeChanger
        },
        setup() {

            const refreshAOSHard = getCurrentInstance()?.appContext.config.globalProperties.$aos?.refreshHard
            const { isCNLocale } = useCms()
            const route = useRoute()
            const data = ref({})
            const openSourceUrls = ref([])
            const openSourcePackages = ref([])
            const isLoading = ref<boolean>(true)

            watchEffect(() => {

                const path = isCNLocale.value ? `/cms/cn/services/${ route.params.slug }.json` : `/cms/en/services/${ route.params.slug }.json`

                fetch(path)
                    .then(response => response.json())
                    .then(response => {

                        data.value = response

                        if (response.open_source_items !== null && !response.open_source_items.isEmpty) {

                            for (const item of response.open_source_items) {

                                openSourceUrls.value.push(item.api_url)

                            }

                        }

                    }).then(() => {

                        Promise.all(openSourceUrls.value.map(url => fetch(url))).then(responses =>

                            Promise.all(responses.map(res => res.json()))

                        )
                            .then(responses => {

                                openSourcePackages.value = responses

                            })

                        isLoading.value = false

                    })
                    .then(nextTick)
                    .then(() => {

                        refreshAOSHard?.()

                    })

            })

            return {
                Theme,
                Route,
                service: data,
                openSourcePackages,
                isLoading
            }

        }
    })

