

    import { defineComponent } from 'vue'
    import { useCms } from '../compositions/UseCms'
    import Grid from './Grid/Grid.vue'
    import Column from './Grid/Column.vue'
    import Heading from './Heading.vue'
    import Button from './Button.vue'
    import { Route } from '../enums/Route'

    export default defineComponent({
        name: 'AgileTeams',
        components: {
            Grid,
            Column,
            Heading,
            Button
        },
        setup() {

            const { agileTeams } = useCms()

            return {
                agileTeams,
                Route
            }

        }
    })

