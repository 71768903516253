

    import { defineComponent, PropType } from 'vue'
    import { PackageInterface } from '../Interfaces/MetricsInterface'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    import Container from './Container.vue'
    import Paragraph from './Paragraph.vue'
    import Picture from './Picture.vue'
    import Heading from './Heading.vue'
    import ExternalLink from './Icons/ExternalLink.vue'
    import Anchor from './Anchor.vue'
    import { Route } from '../enums/Route'
    import { useTranslation } from '../compositions/UseTranslation'
    import { useCms } from '../compositions/UseCms'

    export default defineComponent({
        name: 'OpenSourcePackage',
        components: {
            Column,
            Grid,
            Container,
            Anchor,
            ExternalLink,
            Heading,
            Paragraph,
            Picture
        },
        props: {
            data: { type: Object as PropType<PackageInterface>, required: true },
            featured: { type: Boolean, default: false }
        },
        setup() {

            const { isCNLocale } = useCms()

            return {
                Route,
                isCNLocale
            }

        }
    })

